import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import emailjs from '@emailjs/browser';
import env from "react-dotenv";
import { useState } from 'react';

const Contact = () => {
  const form = useRef();
  const [isSend, setIsSend] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(env.REACT_APP_SERVICE_ID, env.REACT_APP_TEMPLATE_ID, form.current, env.REACT_APP_USER_ID)
      .then((result) => {
        console.log(result.text);
        setIsSend(true)
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <section className='py-16 lg:section' id='contact'>
      <div className='container mx-auto pt-[26rem]'>
        <div className='flex flex-col lg:flex-row'>
          <motion.div 
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex justify-start items-center'
          >
            <div>
              <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide'>Restons en contact</h4>
              <h2 className='text-[45px] lg:text-[90px] leading-none mb-12'>Travaillons<br/>ensemble !</h2>
            </div>
          </motion.div>
          <motion.form 
            variants={fadeIn('left', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 border rounded-2xl flex flex-col gap-y-6 p-6 items-start'
            ref={form} 
            onSubmit={sendEmail}
          >
            <input type='text' name="user_name" placeholder='Votre nom' className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'/>
            <input type='text' name="user_email" placeholder='Votre email' className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'/>
            <textarea placeholder='Your message' name="message" className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'/>
            <button className='btn btn-lg mt-6'>Envoyer</button>
            
            {
              isSend == true && (
                <div className="w-full bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                  <div className="flex">
                    <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                    <div>
                      <p className="font-bold">Votre message a bien été envoyé</p>
                    </div>
                  </div>
                </div>
              )
            }
          </motion.form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
