import React from 'react';
import Image from '../assets/me.png';
import { FaGithub } from 'react-icons/fa';
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Banner = () => {
  return (
    <section className='min-h-[85vh] lg-min-h-[78vh] flex items-center' id="home">
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1
              variants={fadeIn('up', 0.3)} 
              initial="hidden" 
              whileInView={'show'} 
              viewport={{once: false, amount: 0.7}} 
              className='text-[55px] font-bold leading-[0.8] lg:text-[98px]'
            >
              Alizée <span>Gillouaye</span>
            </motion.h1>
            <motion.div
              variants={fadeIn('up', 0.4)} 
              initial="hidden" 
              whileInView={'show'} 
              viewport={{once: false, amount: 0.7}} 
              className='mb-6 text-[30px] lg:text[60px] font-secondary font-semibold uppercase leading-[1]'
            >
              <span className='text-white mr-4'>Développeuse</span>
              <TypeAnimation 
                sequence={[
                  "Front End", 200,
                  "Back End", 200,
                  "Full Stack", 200
                ]} 
                speed={50} 
                className='text-accent' 
                wrapper='span' 
                repeat={Infinity}
              />
            </motion.div>
            <motion.p
              variants={fadeIn('up', 0.5 )} 
              initial="hidden" 
              whileInView={'show'} 
              viewport={{once: false, amount: 0.7}} 
              className='mb-8 max-w-lg mx-auto lg:mx-0'
            >
              Jeune développeuse fraîchement diplômée après cinq années d'études, je suis à la recherche d'un poste en CDI. Mon portfolio reflète mon parcours, mettant en avant mes compétences et projets. Prête à contribuer et apprendre au sein d'une équipe dynamique.
            </motion.p>
            <motion.div
              variants={fadeIn('up', 0.6 )} 
              initial="hidden" 
              whileInView={'show'} 
              viewport={{once: false, amount: 0.7}} 
              className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'
            >
              <button className='btn btn-lg'>Me Contacter</button>
              <a href='#work' className='text-gradient btn-link'>Mon Portfolio</a>
            </motion.div>
            {/* <motion.div
              variants={fadeIn('up', 0.7 )} 
              initial="hidden" 
              whileInView={'show'} 
              viewport={{once: false, amount: 0.7}}
              className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'
            >
              <a href='#'><FaGithub/></a>
            </motion.div> */}
          </div>
          <div className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px] mx-auto'>
            <img src="https://alizeegillouaye.com/v1/images/photo_alizee.png" className='rounded-full'/>
          </div>
        </div>
      </div>
    </section>)
  ;
};

export default Banner;
