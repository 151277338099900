import React from 'react';
import Logo from '../assets/logo.svg';
import { Link } from 'react-scroll';

const Header = () => {
  return <header className='py-8'>
    <div className='container mx-auto'>
      <div className='flex justify-between items-center'>
        <a href="#" className='flex flex-col uppercase'>
          <h4 className='h3 m-0 p-0 leading-none text-gradient'>
            Alizée
          </h4>
          <h4 className='h3 m-0 p-0 leading-none'>
            Gillouaye
          </h4>
        </a>
        <Link
          to="contact" 
          className='btn btn-sm cursor-pointer flex items-center justify-center'
        >
          Travaillez avec moi
        </Link>
      </div>
    </div>
  </header>;
};

export default Header;
