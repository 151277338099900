import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import ShareMyFood from '../assets/ShareMyFood.png';
import Itihel from '../assets/Itihel.png';

const languages = [
  {
    name: 'HTML/CSS',
    color: 'bg-gray-500'
  },
  {
    name: 'JS',
    color: 'bg-red-500'
  },
  {
    name: 'PHP',
    color: 'bg-indigo-500'
  },
  // {
  //   name: 'React Native',
  //   color: 'bg-orange-200'
  // },
  {
    name: 'Flutter',
    color: 'bg-orange-500'
  },
  {
    name: 'Python',
    color: 'bg-yellow-500'
  },
  {
    name: 'Symfony',
    color: 'bg-green-500'
  },
  {
    name: 'React',
    color: 'bg-blue-500'
  },
  // {
  //   name: 'Angular',
  //   color: 'bg-neutral-300'
  // },
  {
    name: 'Wordpress',
    color: 'bg-purple-500'
  },
  {
    name: 'Drupal',
    color: 'bg-pink-500'
  },
]

const Work = () => {
  return (
    <section className="section" id="work">
      <div className='container mx-auto pt-[26rem]'>
        <div>
          <motion.h2 
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="h2 leading-tight text-accent text-center"
          >
            Mes derniers projets
          </motion.h2>
          <motion.p 
            variants={fadeIn('left', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className='max-w-md mb-16 text-center mx-auto'
          >
            Explorez mes projets récents qui mettent en avant ma passion pour la programmation et mes compétences dans divers langages et frameworks, notamment :
          </motion.p>
          <motion.div 
            className="flex space-x-3 justify-center mb-16"
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
          >
            {languages.map((language, index) => {
              const {name, color} = language
              return (
                <span
                className={`px-4 font-medium text-base ${color} text-white rounded-full py-1.5`}
                  key={index}
                >
                  {name}
                </span>
              )
            })}
          </motion.div>
          <motion.div 
            className='flex justify-center mb-16'
            variants={fadeIn('left', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
          >
            <a href="https://github.com/alizeegly" target="_blank" className='btn btn-sm flex items-center'>Voir tous mes projets</a>
          </motion.div>
        </div>
        <div className='flex flex-col lg:flex-row gap-x-10'>
          <motion.div 
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0 justify-center'
          >
            <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl h-[315px]'>
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300 h-[315px]'>
              <img className='group-hover:scale-125 transition-all duration-500' src={ShareMyFood} alt="work-1"/></div>
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-white'>Flutter - Firebase</span>
              </div>
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-500 z-50'>
                <span className='text-3xl text-white'>Share My Food</span>
              </div>
            </div>

            <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl h-[315px]'>
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300 h-[315px]'>
              <img className='group-hover:scale-125 transition-all duration-500 w-full h-full object-cover' src={"https://alizeegillouaye.com/v1/images/portfolio/convertisseur.png"} alt="work-1" /></div>
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-white'>Xcode - Swift</span>
              </div>
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-500 z-50'>
                <span className='text-3xl text-white'>Convertisseur</span>
              </div>
            </div>
          </motion.div>
          <motion.div
            className='flex-1 flex flex-col gap-y-10'
            variants={fadeIn('left', 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
          >
            <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl h-[315px]'>
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300 h-[315px]'></div>
              <img className='group-hover:scale-125 transition-all duration-500' src={Itihel} alt="work-2"/>
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-white'>React - Nodejs</span>
              </div>
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-500 z-50'>
                <span className='text-3xl text-white'>Itihel</span>
              </div>
            </div>
            <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl h-[315px]'>
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300 h-[315px]'></div>
              <img className='group-hover:scale-125 transition-all duration-500' src={"https://alizeegillouaye.com/v1/images/shooter/ecran-accueil.png"} alt="work-3"/>
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-white'>Python - Pygame</span>
              </div>
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-500 z-50'>
                <span className='text-3xl text-white'>Shooter</span>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Work;
